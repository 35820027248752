
import { defineComponent, computed } from "vue";
import MModal from "@/views/Payment/Mobile/Components/MModal.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
      default: "success",
    },
  },
  setup(props, context) {
    const router = useRouter();
    const visibleModel = computed({
      get() {
        return props.visible;
      },
      set(value) {
        context.emit("update:visible", value);
        router.push({
          path: '/mobile/management/pending/pproval'
        });
      },
    });
    return { visibleModel };
  },
  components: {
    MModal,
  },
});
